import styled, { themeGet, device, css } from "@styled";
import quoteBG from "@data/images/bg/quote-bg.png";

export const SectionWrap = styled.section`
  padding-block-start: 50px;
  padding-block-end: 60px;
  position: relative;
  ${device.medium} {
    padding-block-start: 70px;
    padding-block-end: 80px;
  }
  ${device.large} {
    padding-block-start: 90px;
    padding-block-end: 100px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 250px;
    height: 250px;
    opacity: 0.05;
    background: url(${quoteBG});
    background-size: contain;
    background-repeat: no-repeat;
    left: 13%;
    top: 20px;
    ${device.large} {
      left: 2%;
    }
    ${device.xxlarge} {
      left: 13%;
    }
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: #fff;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: #f8f8f8;
    `}
`;

export const SliderWrap = styled.div`
  margin-top: 70px;
  .slick {
    &-list {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
      ${device.medium} {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
      }
    }
    &-slide {
      transform: scale(1);
      opacity: 0.6;
      position: relative;
      transition: ${themeGet("transition")};
      padding: 10px 0;
      filter: drop-shadow(0 4px 6px rgba(0 0 0 / 55%));
    }
    &-active {
      transform: scale(1, 1.1);
      opacity: 0.6;
    }
    &-center {
      transform: scale(1.2, 1.1);
      ${device.medium} {
        transform: scale(1.8, 1.4);
      }
      opacity: 1;
      z-index: 9;
      .gatsby-image-wrapper {
        img {
          transform: scale(1, 1.1);
        }
      }
      h4 {
        transform: scale(1, 1.1);
      }
    }
    &-dots {
      li {
        width: 10px;
        height: 10px;
        border: 1px solid #000;
        button {
          padding: 0;
          width: 10px;
          height: 10px;
          &::before {
            content: none;
          }
        }
        &.slick-active {
          border-color: #f94f19;
          background: #f94f19;
        }
      }
    }
  }
`;
export const SliderWrapInner = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      max-height: 400px;
    }
  }
  .swiper-pagination {
    bottom: 0;
  }
  h4 {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 15px;
    left: 0;
    right: 0;
    line-height: 1;
    background: linear-gradient(to top, black, transparent);
    .sliderDesc {
      font-size: 9px;
      display: block;
      margin-top: 5px;
      line-height: 1.4;
      font-weight: 400;
    }
  }
`;
