import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Button from "@ui/button";
import PropTypes from "prop-types";
import Heading from "@ui/heading";
import { SectionWrap, SliderWrap, SliderWrapInner } from "./style";
import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  centerMode: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Slider3D = ({ data, layout, ...props }) => {
  return (
    <SectionWrap layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              title={data.section_title?.title}
              subtitle={data.section_title?.subtitle}
              description={data.section_title?.description}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mb-4">
            <SliderWrap>
              <Slider {...settings}>
                {data?.items &&
                  data?.items?.map((el, index) => (
                    <SliderWrapInner key={index}>
                      <Image src={el?.images[0]?.src} alt={el?.alt || "Logo"} />
                      <Heading as="h4">
                        {el?.title}
                        {el.description && <span className="sliderDesc">{el.description}</span>}
                      </Heading>
                    </SliderWrapInner>
                  ))}
              </Slider>
            </SliderWrap>
          </Col>
          <Col lg={12} className="text-center mt-4">
            {!!data?.buttons?.length &&
              data?.buttons?.map(({ id, content, ...rest }) => (
                <Button key={id} m="7px" {...rest}>
                  {content}
                </Button>
              ))}
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

Slider3D.propTypes = {
  layout: PropTypes.oneOf([1, 2]),
  items: PropTypes.object,
};
Slider3D.defaultProps = {
  layout: 1,
};

export default Slider3D;
